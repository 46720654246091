<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Forgot Password v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <!-- logo -->
                    <vuexy-logo />

                    <h2 class="brand-text text-primary ml-1">
                        {{ $t('appName') }}
                    </h2>
                </b-link>

                <b-card-title class="mb-1">
                    {{ $t('twofa.title') }}
                </b-card-title>
                <b-card-text class="mb-2">
                    {{ $t('twofa.description') }}
                </b-card-text>

                <!-- form -->
                <validation-observer ref="twoFAForm">
                    <b-form
                        class="auth-forgot-password-form mt-2"
                        @submit.prevent="validationForm"
                    >
                        <!-- email -->
                        <b-form-group
                            :label="$t('ui.code')"
                            label-for="two-fa-code"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('ui.code')"
                                :rules="`required|digits:${twofaCodeOptions.length}`"
                            >
                                <b-form-input
                                    id="two-fa-code"
                                    v-model.trim="twoFACode"
                                    :state="errors.length > 0 ? false : null"
                                    name="two-fa-code"
                                    placeholder="123456"
                                    :maxlength="twofaCodeOptions.length"
                                    type="tel"
                                    @keypress="onlyNumber"
                                />
                                <small class="text-danger">
                                    {{ errors[0] }}
                                </small>
                            </validation-provider>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button variant="primary" block type="submit">
                            {{ $t('ui.confirm') }}
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <b-link :to="{ name: 'login', params: { enteredLogin } }">
                        <feather-icon icon="ChevronLeftIcon" />
                        {{ $t('twofa.previous') }}
                    </b-link>
                </b-card-text>
            </b-card>
            <!-- /Forgot Password v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
} from 'bootstrap-vue';
import { required, email, digits } from '@validations';
import useAuth from '@/views/pages/useAuth';

export default {
    components: {
        VuexyLogo,
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BFormGroup,
        BFormInput,
        BButton,
        BForm,
        ValidationProvider,
        ValidationObserver,
    },

    props: {
        enteredLogin: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            twoFACode: '',
            // validation
            required,
            email,
            digits,
        };
    },

    computed: {
        locale() {
            return this.$i18n.locale;
        },
    },

    watch: {
        locale() {
            this.$refs.twoFAForm.reset();
        },
    },

    methods: {
        validationForm() {
            this.$refs.twoFAForm.validate().then((success) => {
                if (success) {
                    this.$router.push({ name: 'home' });
                }
            });
        },

        onlyNumber($event) {
            const keyCode = $event.keyCode ? $event.keyCode : $event.which;

            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
    },

    setup() {
        const { twofaCodeOptions } = useAuth();

        return {
            twofaCodeOptions,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
